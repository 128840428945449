import { productPlaceholder } from '@assets/placeholders'
import { useModalAction } from '@components/common/modal/modal.context'
import Button from '@components/ui/button'
import Image from '@components/ui/image'
import { useCart } from '@contexts/cart/cart.context'
import { UserContext } from '@contexts/user/user.context'
import { IUser } from '@contexts/user/user.utils'
import usePrice from '@framework/product/use-price'
import {
  createPricePayload,
  getCurrentPrice,
  getDiscountedPrice,
} from '@utils/helper'
import ProductTitle from '@utils/product-title'
import { ROUTES } from '@utils/routes'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoClose } from 'react-icons/io5'

const MiniCart = () => {
  const { items, isMiniCartitem, setIsMiniCartItem } = useCart()
  const miniCartItem = items.find((item) => item.sku === isMiniCartitem?.sku)
  const quantity = miniCartItem?.quantity || 0

  const { user, getCart, setIsProductSync } = useContext(UserContext) as IUser
  const { t: translate } = useTranslation('common')
  const { closeModal } = useModalAction()
  const { data: session } = useSession()
  const router = useRouter()

  const [loading, setLoading] = useState(false)
  const [cartLoading, setCartLoading] = useState(false)
  const catalogRulePrice = isMiniCartitem?.catalog_rule_price

  const tierPrices = miniCartItem?.mag_attributes?.find(
    (attr: { attribute_code: string }) => attr.attribute_code === 'tier_prices',
  )?.value

  useEffect(() => {
    if (isMiniCartitem) {
      closeModal()
      const timer = setTimeout(() => {
        setIsMiniCartItem(null)
      }, 5000) // 5 seconds delay

      return () => clearTimeout(timer) // Cleanup on unmount
    }
  }, [isMiniCartitem])

  const clearItem = () => {
    setIsMiniCartItem(null)
  }

  if (!isMiniCartitem) return null // Hide component when not needed

  const currentPrice = getCurrentPrice(createPricePayload(isMiniCartitem))

  const finalCurrentPrice = getDiscountedPrice(
    user?.group_id,
    catalogRulePrice,
    currentPrice,
    session,
    tierPrices,
  )

  const { price: formattedCurrentPrice } = usePrice({
    amount: finalCurrentPrice,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })

  const { price: formattedSubtotal } = usePrice({
    amount: finalCurrentPrice * quantity,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })

  const redirectToCheckout = async () => {
    setLoading(true)
    await getCart()
    setLoading(false)
    router.push(ROUTES.CHECKOUT)
    setIsMiniCartItem(null)
  }
  const redirectToCart = async () => {
    setCartLoading(true)
    await getCart()
    setCartLoading(false)
    setIsProductSync(true)
    router.push(ROUTES.CART)
    setIsMiniCartItem(null)
  }

  return (
    <div className="font-sofia bg-red rounded-lg flex justify-center sm:justify-end px-4 sm:px-0 -sm:px-[6%]">
      <div className="fixed p-3 bg-white z-[9999]   mx-10  w-[96%] sm:w-[405px] shadow-md drop-shadow-lg">
        <div className="small-cart_content">
          <IoClose
            onClick={clearItem}
            fontSize={22}
            className="relative cursor-pointer ml-auto hover:opacity-60"
          />
          <h1 className="text-brand-green text-lg text-center pb-4">
            {translate('text-minicart-title')}
          </h1>

          <div className="flex items-center">
            <div className="mr-4">
              <Image
                src={isMiniCartitem?.image || productPlaceholder}
                width={100}
                height={100}
                className="mr-4 max-h-[100px]"
              />
            </div>
            <span>
              {isMiniCartitem && (
                <p className="text-brand-dark font-medium text-sm">
                  <ProductTitle item={isMiniCartitem} />
                </p>
              )}
              <span className="flex justify-between items-center mt-3 pb-1">
                <span className="text-xs font-medium">
                  {translate('text-sold-by')} :{' '}
                  <span className="text-brand-dark">
                    {translate('text-website-name')}
                  </span>
                  <span className="mb-2 c-mini-cart-notification__cart-sold-by-vendor-label"></span>
                </span>
                <span className="font-semibold text-brand">
                  {formattedCurrentPrice}
                </span>
              </span>
              <div className="flex justify-between font-medium border-t-2 border-dashed pt-1">
                <span>{translate('text-minicart-subtotal')} :</span>

                <span>{formattedSubtotal}</span>
              </div>
            </span>
          </div>

          <div className="flex space-x-1 font-sofia mt-6">
            <Button
              className="w-full font-sofia"
              onClick={redirectToCheckout}
              loading={loading}
              disabled={loading}
            >
              {translate('text-checkout')}
            </Button>
            <Button
              className="w-full bg-brand-lightgraytrans font-sofia !text-brand-dark"
              onClick={redirectToCart}
              loading={cartLoading}
              disabled={cartLoading}
            >
              {translate('text-proceed-to-cart')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MiniCart
