import { useCart } from '@contexts/cart/cart.context'
import { useCheckout } from '@contexts/checkout/checkout.context'
import { UserContext } from '@contexts/user/user.context'
import { IUser } from '@contexts/user/user.utils'

import { ROUTES } from '@utils/routes'
import { signOut } from 'next-auth/react'
import Router from 'next/router'
import { useContext } from 'react'
import { useMutation } from 'react-query'

export interface LoginInputType {
  email: string
  password: string
  remember_me: boolean
}
async function logout() {
  return {
    ok: true,
    message: 'Logout Successful!',
  }
}
export const useLogoutMutation = () => {
  const { setCreateCart, resetAll, userApi } = useContext(UserContext) as IUser
  const { resetFormStates } = useCheckout()
  const { logoutResetCart } = useCart()

  return useMutation(() => logout(), {
    onSuccess: async (_data) => {
      logoutResetCart()
      await signOut({ redirect: false })
      userApi({ stopBrearerToken: true })
      resetAll()
      resetFormStates()
      setCreateCart(true)
      localStorage.removeItem('borobazar-cart')
      localStorage.setItem('logged-out', 'true')

      setTimeout(() => {
        Router.push(ROUTES.HOME)
      }, 1500)
    },
    onError: (data) => {
      console.error(data, 'logout error response')
    },
  })
}
