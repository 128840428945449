import { useState, useEffect } from 'react'
import { FaArrowUpLong } from 'react-icons/fa6'
import Button from '@components/ui/button'

const ScrollToTopButton = ({ hasStickyBar = false }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }
    window.addEventListener('scroll', toggleVisibility)

    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    isVisible && (
      <Button
        onClick={scrollToTop}
        className={`fixed right-5 !p-3 !h-[42px] !text-brand-black border border-black border-solid bg-white rounded-full transition"
            ${hasStickyBar ? 'bottom-48 md:bottom-28' : 'bottom-5'}`}
      >
        <FaArrowUpLong />
      </Button>
    )
  )
}

export default ScrollToTopButton
