import WidgetsSubscription from '@components/layout/footer/widget/widget-subscription'
import Image from '@components/ui/image'
import Link from 'next/link'
import { FC } from 'react'
import { footer } from './data'

import { useIsUSLocale } from '@utils/helper'
import { categories } from '@utils/main-categories'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import { FACEBOOK_URLS, INSTAGRAM_URLS } from '@constants/index'

interface FooterProps {
  variant?: 'default' | 'medium'
}

const { widgets, payment } = footer

const HomeFooter: FC<FooterProps> = ({ variant = 'default' }) => {
  const { t: translate } = useTranslation(['common', 'home', 'footer', 'menu'])
  const isUS = useIsUSLocale()

  return (
    <footer className="home-footer">
      <div
        className="flex flex-col pt-4 sm:pt-[80px] bg-brand-darkblue text-white min-h-[677px]"
        style={{ color: 'white' }}
      >
        <div
          className="container-- mx-auto px-4"
          style={{ margin: '0 auto', flexGrow: 1 }}
        >
          <div className="flex xs:flex-row xsp:flex-row flex-wrap text-center-- sm:text-left sm:justify-center md:justify-around   xsp:gap-5 lg:gap-[23px] lgp:gap-[51px] xl:gap-[90px] xlp:gap-[100px] 1xl:gap-[150px] 2xl:gap-[180px] 3xl:gap-[250px] 4xl:gap-[250px] xs:zoom-67 xsp:zoom-90 4xl:zoom-100">
            {/* FoodServiceDirect Column */}
            <div className="[@media_(max-width:576px)]:flex-1 mt-10-- md:mt-0">
              <h3 className="font-poppins font-medium text-[20px] sm:text-[1.5rem] mb-8 md:mb-6">
                FoodServiceDirect
              </h3>
              <ul className="space-y-2 font-poppins font-medium text-[1.125rem]">
                <li>
                  <Link
                    href="/about-us"
                    data-testid="footer-link-about-us"
                    className="hover:text-gray-300 text-[14px] sm:text-lg"
                  >
                    {translate('link-about-us', { ns: 'footer' })}
                  </Link>
                </li>
                <li>
                  <Link
                    href="/accessibility"
                    data-testid="footer-link-accessibility-statement"
                    className="hover:text-gray-300 text-[14px] sm:text-lg"
                    target={'_self'}
                  >
                    {translate('accessibility_statement', { ns: 'footer' })}
                  </Link>
                </li>
                <li>
                  <Link
                    href="our-promise"
                    data-testid="link-our-promise"
                    className="hover:text-gray-300 text-[14px] sm:text-lg"
                  >
                    {translate('link-our-promise', { ns: 'footer' })}
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://foodservicedirect.zendesk.com/hc/en-us/sections/360001999373-Account-Information"
                    target={isUS ? '_blank' : '_self'}
                    data-testid="link-account-information"
                    className="hover:text-gray-300 text-[14px] sm:text-lg"
                  >
                    {translate('link-account-information', { ns: 'footer' })}
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://foodservicedirect.zendesk.com/hc/en-us/sections/360001987873-Shipping"
                    target={isUS ? '_blank' : '_self'}
                    data-testid="link-shipping"
                    className="hover:text-gray-300 text-[14px] sm:text-lg"
                  >
                    {translate('link-shipping', { ns: 'footer' })}
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://foodservicedirect.zendesk.com/hc/en-us/sections/360001987873-Shipping"
                    target={isUS ? '_blank' : '_self'}
                    data-testid="link-cancellation-&-returns"
                    className="hover:text-gray-300 text-[14px] sm:text-lg"
                  >
                    {translate('link-cancellation-&-returns', { ns: 'footer' })}
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://foodservicedirect.zendesk.com/hc/en-us/sections/360001982694-Finding-an-Item"
                    target={isUS ? '_blank' : '_self'}
                    data-testid="link-find-an-item"
                    className="hover:text-gray-300 text-[14px] sm:text-lg"
                  >
                    {translate('link-finding-an-items', { ns: 'footer' })}
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://foodservicedirect.zendesk.com/hc/en-us/sections/360001644714-Ordering"
                    target={isUS ? '_blank' : '_self'}
                    data-testid="link-ordering"
                    className="hover:text-gray-300 text-[14px] sm:text-lg"
                  >
                    {translate('link-ordering', { ns: 'footer' })}
                  </Link>
                </li>
                <li>
                  <Link
                    href={
                      isUS
                        ? 'https://support.foodservicedirect.com/hc/en-us'
                        : '/faq'
                    }
                    target={isUS ? '_blank' : '_self'}
                    data-testid="footer-link-faq"
                    className="hover:text-gray-300 text-sm sm:text-lg"
                  >
                    FAQ
                  </Link>
                </li>
              </ul>
              <div className="mt-2.5 [@media_(min-width:577px)]:hidden">
                <h3 className="font-poppins font-medium text-[20px] sm:text-[1.5rem] mb-6">
                  {translate('menu-let-us-help-you', { ns: 'menu' })}
                </h3>
                <ul className="space-y-2 font-poppins font-medium text-[1.125rem]">
                  <li>
                    <Link
                      href="/return"
                      className="hover:text-gray-300 text-sm sm:text-lg"
                      data-testid="footer-link-returns-and-replacements"
                    >
                      {translate('link-returns-and-replacements', {
                        ns: 'footer',
                      })}
                    </Link>
                  </li>
                  <li>
                    <Link
                      target="_blank"
                      href="https://support.foodservicedirect.com/hc/en-us/requests/new"
                      className="hover:text-gray-300 text-sm sm:text-lg"
                      data-testid="footer-link-contact-us"
                    >
                      {translate('link-contact-us', { ns: 'footer' })}
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="#"
                      data-testid="footer-link-about-us"
                      className="hover:text-gray-300 text-[14px] sm:text-lg"
                    >
                      {translate('link-help', { ns: 'footer' })}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* Categories Column */}
            <div className="[@media_(max-width:576px)]:flex-1 [@media_(max-width:576px)]:mt-16 mt-10-- md:mt-0">
              <h3 className="font-poppins font-medium text-[20px] sm:text-[1.5rem] [@media_(max-width:576px)]:mb-2 mb-6">
                {translate('menu-categories', { ns: 'menu' })}
              </h3>
              <ul className="space-y-2 font-poppins font-medium text-[1.125rem]">
                {categories.data.map((category, index) => (
                  <li key={index}>
                    <Link
                      href={`/${category.slug}`}
                      data-testid={`footer-link-category-${category.slug.replace(
                        '.html',
                        '',
                      )}`}
                      className="hover:text-gray-300 text-sm sm:text-lg"
                    >
                      {/* {category.name} */}
                      {translate(
                        `categories:${category.name.toLocaleLowerCase()}`,
                      )}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link href="/deals">
                    {translate('direct_link', { ns: 'footer' })}
                  </Link>
                </li>
              </ul>
            </div>
            {/* Let Us Help You Column */}
            <div className="mt-10-- md:mt-0 max-w-[224px] [@media_(max-width:576px)]:hidden">
              <h3 className="font-poppins font-medium text-[1.5rem] mb-6">
                {translate('menu-let-us-help-you', { ns: 'menu' })}
              </h3>
              <ul className="space-y-2 font-poppins font-medium text-[1.125rem]">
                <li>
                  <Link
                    href="/return"
                    className="hover:text-gray-300"
                    data-testid="footer-link-returns-and-replacements"
                  >
                    {translate('link-returns-and-replacements', {
                      ns: 'footer',
                    })}
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    href="https://support.foodservicedirect.com/hc/en-us/requests/new"
                    className="hover:text-gray-300"
                    data-testid="footer-link-contact-us"
                  >
                    {translate('link-contact-us', { ns: 'footer' })}
                  </Link>
                </li>
              </ul>
            </div>
            {/* Newsletter Signup */}
            <div className="w-auto max-w-[100%] sm:max-w-[363px]">
              <div className="font-poppins font-medium text-[1.125rem]  sm:mt-[66px] mb-1">
                {translate('text-newletter-info')}
              </div>

              <WidgetsSubscription
                innerClassName="ps-0 [@media_(max-width:576px)]:-mt-3"
                isIconNotRequired={true}
              />

              <h3 className="font-poppins font-medium text-[1.5rem] mb-4">
                {translate('menu-questions', { ns: 'menu' })} ?
              </h3>

              <div className="flex items-center flex-wrap gap-y-4 justify-start">
                <Link
                  target="_blank"
                  href={`https://support.foodservicedirect.com/hc/en-us/requests/new`}
                  className={cn(
                    'text-brand-darkblue px-[24px] py-2 mr-7 sm:mr-5 bg-white rounded border text-base font-medium leading-6 text-left',
                  )}
                  data-testid="footer-btn-contact-us"
                >
                  {translate('text-contact-us')}
                </Link>

                <Link
                  href={
                    isUS
                      ? 'https://support.foodservicedirect.com/hc/en-us'
                      : '/faq'
                  }
                  target={isUS ? '_blank' : '_self'}
                  className={cn(
                    'px-[24px] py-2 bg-brand rounded border text-base font-medium leading-6 text-left',
                  )}
                  data-testid="footer-btn-faq"
                >
                  {translate('text-visit-faqs')}
                </Link>
              </div>
              {/* </form> */}

              <div className="flex items-center justify-center sm:gap-6  gap-7 flex-1  my-10 mx-2 sm:mx-auto  ">
                <div className="flex">
                  <Link
                    href={isUS ? FACEBOOK_URLS.US : FACEBOOK_URLS.CA}
                    data-testid="footer-link-social-facebook"
                  >
                    <Image
                      src={'/assets/images/landing-one/facebook-icon.svg'}
                      alt={'facebook'}
                      width={40}
                      height={40}
                      quality={100}
                      loading="lazy"
                      className={' w-[60px] h-[60px] sm:w-[45px] sm:h-[45px]'}
                    />
                  </Link>
                </div>

                <div className="flex">
                  <Link
                    href={`https://www.youtube.com/channel/UC61Ow7SDQ1PIBRalrbNl4EA`}
                    data-testid="footer-link-social-youtube"
                  >
                    <Image
                      src={'/assets/images/landing-one/youtube-icon.svg'}
                      alt={'youtube'}
                      width={40}
                      height={40}
                      quality={100}
                      loading="lazy"
                      className={' w-[60px] h-[60px] sm:w-[45px] sm:h-[45px]'}
                    />
                  </Link>
                </div>

                <div className="flex">
                  <Link
                    href={`https://www.pinterest.com/foodservicedirect/`}
                    data-testid="footer-link-social-pinterest"
                  >
                    <Image
                      src={'/assets/images/landing-one/pinterest-icon.svg'}
                      alt={'pinterest'}
                      width={40}
                      height={40}
                      quality={100}
                      loading="lazy"
                      className={' w-[60px] h-[60px] sm:w-[45px] sm:h-[45px]'}
                    />
                  </Link>
                </div>

                <div className="flex">
                  <Link
                    href={`https://twitter.com/foodsrvcdirect`}
                    data-testid="footer-link-social-twitter"
                  >
                    <Image
                      src={'/assets/images/landing-one/twitter-icon.svg'}
                      alt={'twitter'}
                      width={40}
                      height={40}
                      quality={100}
                      loading="lazy"
                      className={' w-[60px] h-[60px] sm:w-[45px] sm:h-[45px]'}
                    />
                  </Link>
                </div>

                <div className="flex">
                  <Link
                    href={isUS ? INSTAGRAM_URLS.US : INSTAGRAM_URLS.CA}
                    data-testid="footer-link-social-instagram"
                  >
                    <Image
                      src={'/assets/images/landing-one/instagram-icon.svg'}
                      alt={'instagram'}
                      width={40}
                      height={40}
                      quality={100}
                      loading="lazy"
                      className={' w-[60px] h-[60px] sm:w-[45px] sm:h-[45px]'}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col-reverse lg:flex-row flex-wrap-reverse gap-2 sm:px-6 items-center justify-around mx-auto bg-white text-brand-darkblue py-[11px] w-full mt-auto">
          <div className="flex flex-row flex-wrap justify-center items-center gap-6 sm:pb-20 lg:!pb-0">
            <div className="flex w-[47px] h-[48px] relative overflow-hidden">
              <Image
                src={'/assets/images/landing-one/paypal-icon.svg'}
                alt={'paypal'}
                fill={true}
                quality={100}
                loading="lazy"
                className="object-cover bg-fill-thumbnail  [@media_(max-width:576px)]:object-contain"
              />
            </div>

            <div className="flex w-[47px] h-[29px] relative overflow-hidden">
              <Image
                src={'/assets/images/landing-one/mastercard-icon.svg'}
                alt={'mastercard'}
                fill={true}
                quality={100}
                loading="lazy"
                className="object-cover bg-fill-thumbnail  [@media_(max-width:576px)]:object-contain"
              />
            </div>

            <div className="flex w-[48px] h-[48px] relative overflow-hidden">
              <Image
                src={'/assets/images/landing-one/visa-icon.svg'}
                alt={'visa'}
                fill={true}
                quality={100}
                loading="lazy"
                className="object-cover bg-fill-thumbnail  [@media_(max-width:576px)]:object-contain"
              />
            </div>

            <div className="flex w-[43px] h-[43px] relative overflow-hidden">
              <Image
                src={'/assets/images/landing-one/discover-icon.svg'}
                alt={'discover'}
                fill={true}
                quality={100}
                loading="lazy"
                className="object-cover bg-fill-thumbnail  [@media_(max-width:576px)]:object-contain"
              />
            </div>

            <div className="flex w-[87px] h-[26px] relative overflow-hidden">
              <Image
                src={'/assets/images/landing-one/securetrust-icon.svg'}
                alt={'securetrust'}
                fill={true}
                quality={100}
                loading="lazy"
                className="object-cover bg-fill-thumbnail  [@media_(max-width:576px)]:object-contain"
              />
            </div>
          </div>

          <ul className="flex flex-row flex-wrap  items-center gap-2   sm:gap-[2.5] lg:gap-x-7 sm:gap-y-6 my-0 sm:my-6 lg:my-0 sm:px-6 lg:px-0 font-poppins text-base font-medium text-center sm:text-left lg:pt-0 justify-center">
            <li>
              <Link
                href={`/terms`}
                className={cn('text-[8px] sm:text-sm lg:text-base')}
                data-testid="footer-link-terms-of-use"
              >
                {translate('text-terms-of-use')}
              </Link>
            </li>
            <li>
              <Link
                href={`/return`}
                className={cn('text-[8px] sm:text-sm lg:text-base')}
                data-testid="footer-link-return-policy"
              >
                {translate('text-return-policy')}
              </Link>
            </li>
            <li>
              <Link
                href={`/privacy`}
                className={cn('text-[8px] sm:text-sm lg:text-base')}
                data-testid="footer-link-privacy-policy"
              >
                {translate('text-privacy-policy')}
              </Link>
            </li>
            <li>
              {' '}
              <span className={cn('text-[8px] sm:text-sm lg:text-base')}>
                {' '}
                © {new Date().getFullYear()} {translate('text-all-rights')}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default HomeFooter
