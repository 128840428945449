import { ROUTES } from '@utils/routes'

export const BRANDS = [
  {
    url: `${ROUTES.BRANDS}/acai-root's/`,

    name: 'Acai Roots',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/e53f95b2c4582c83869291a1a2e5b701.png',
  },

  {
    url: `${ROUTES.BRANDS}/advancepierre'/`,

    name: 'AdvancePierre',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/7dd92a4f69e5dd5ae1420b604d275a2e.png',
  },

  {
    url: `${ROUTES.BRANDS}/amoy/`,

    name: 'Amoy',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/ae6ce94302eea22d649463ee26f46e34.png',
  },

  {
    url: `${ROUTES.BRANDS}/ben-and-jerry's/`,

    name: "Ben and Jerry's",

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand_benandjerry.png',
  },

  {
    url: `${ROUTES.BRANDS}/bosco's/`,

    name: "Bosco's",

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/78d4f12aa4fabcf6cefcb154a6bbe4e2.png',
  },

  {
    url: `${ROUTES.BRANDS}/brakebush/`,

    name: 'Brakebush',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/d8f0ef1a041d266abff44a1cad507726.png',
  },

  {
    url: `${ROUTES.BRANDS}/bridor/`,

    name: 'Bridor',

    logo: 'https://drryor7280ntb.cloudfront.net/media/bridor_logo.jpg',
  },

  {
    url: `${ROUTES.BRANDS}/brill/`,

    name: 'Brill',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/822c57771c0b546a264ff6c74f1a99b9.png',
  },

  {
    url: `${ROUTES.BRANDS}/claussen/`,

    name: 'Claussen',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/b1d81a1511a6c4f5e2ea9b31e332cabe.png',
  },

  {
    url: `${ROUTES.BRANDS}/david's/`,

    name: "David's",

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/c1e34e3a81270a56d7e0abf7e0049cd1.png',
  },

  {
    url: `${ROUTES.BRANDS}/dole/`,

    name: 'Dole',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/8e78f0741e24ea499fee9327b292792e.png',
  },

  {
    url: `${ROUTES.BRANDS}/doritos/`,

    name: 'Doritos',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/58acedc452cfe78c60b4b19081662e53.png',
  },

  {
    url: `${ROUTES.BRANDS}/eckrich/`,

    name: 'Eckrich',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/1483403f2583abc13a9d577e50dd509b.png',
  },

  {
    url: `${ROUTES.BRANDS}/extra-melt/`,

    name: 'Extra Melt',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/474422baae093ae2634b6e9da3b081dd.png',
  },

  {
    url: `${ROUTES.BRANDS}/farmland/`,

    name: 'Farmland',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand_farmland.png',
  },

  {
    url: `${ROUTES.BRANDS}/goya/`,

    name: 'Goya',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/c67060c80c259bbff27db46b2dfc0d83.png',
  },

  {
    url: `${ROUTES.BRANDS}/hellmann's/`,

    name: "Hellmann's",

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand_hellmanns.png',
  },

  {
    url: `${ROUTES.BRANDS}/hidden-valley-foodservice/`,

    name: 'Hidden Valley Foodservice',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand_hiddenvalley-min_1.png',
  },

  {
    url: `${ROUTES.BRANDS}/high-liner-foods/`,

    name: 'High Liner Foods',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/2ce4c4d0bc5e05f469a323136243f7c5.png',
  },

  {
    url: `${ROUTES.BRANDS}/jimmy-dean/`,

    name: 'Jimmy Dean',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/98e72e33e06c438e986118b16c62d278.png',
  },

  {
    url: `${ROUTES.BRANDS}/johnsonville/`,

    name: 'Johnsonville',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/a0e8c0300883a5718c913f7c2aca2ca8.png',
  },

  {
    url: `${ROUTES.BRANDS}/knorr-professional/`,

    name: 'Knorr Professional',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand_knorrprofessional.png',
  },

  {
    url: `${ROUTES.BRANDS}/kraft/`,

    name: 'Kraft',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/66cf18f3798d785ac02ae0a6e36dd09a.png',
  },

  {
    url: `${ROUTES.BRANDS}/land-o-lakes/`,

    name: 'Land O Lakes',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/87032047e2b12a12628bc74369a29bfd.png',
  },

  {
    url: `${ROUTES.BRANDS}/los-cabos/`,

    name: 'Los Cabos',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/75df1888c14cb220fb832aa528b0bf90.png',
  },

  {
    url: `${ROUTES.BRANDS}/luigi's/`,

    name: "Luigi's",

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/521d865a000651d035664d1a2285e864.png',
  },

  {
    url: `${ROUTES.BRANDS}/marzetti/`,

    name: 'Marzetti',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/c719f7690b643d6076b01e6fc87b7807.png',
  },

  {
    url: `${ROUTES.BRANDS}/morningstar-farms/`,

    name: 'MorningStar Farms',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/026e7f2597a1e889812ef8b9e310f835.png',
  },

  {
    url: `${ROUTES.BRANDS}/olympia-foods/`,

    name: 'Olympia Foods',

    logo: 'https://drryor7280ntb.cloudfront.net/media/olympiafoods_logo.jpg',
  },

  {
    url: `${ROUTES.BRANDS}/oscar-mayer/`,

    name: 'Oscar Mayer',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/c79c34e204244939bfaf0c80adab62e4.png',
  },

  {
    url: `${ROUTES.BRANDS}/otis-spunkmeyer/`,

    name: 'Otis Spunkmeyer',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/c420798b85d21831da79bbaf3cd0afc0.png',
  },

  {
    url: `${ROUTES.BRANDS}/panapesca/`,

    name: 'PanaPesca',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/ccc5b1b37c9de2f5d099b9658b75c4da.png',
  },

  {
    url: `${ROUTES.BRANDS}/papetti's/`,

    name: "Papetti's",

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/8abb9551a985f8b2abed731d03a14d26.png',
  },

  {
    url: `${ROUTES.BRANDS}/perdue/`,

    name: 'Perdue',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/ed83b3869ef38a36290c8f64d18d107e.png',
  },

  {
    url: `${ROUTES.BRANDS}/philadelphia/`,

    name: 'Philadelphia',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/a4d5603fbb6b95578d298b9bf339c294.png',
  },

  {
    url: `${ROUTES.BRANDS}/pillsbury/`,

    name: 'Pillsbury',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/b64bea63fecfa180fb33b1ed40c37ac8.png',
  },

  {
    url: `${ROUTES.BRANDS}/rich's/`,

    name: "Rich's",

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/fc12c18b6a0f123df2a35661c7c61b8d.png',
  },

  {
    url: `${ROUTES.BRANDS}/rollerbites/`,

    name: 'RollerBites',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/01cf23691fe35b5d331b2c000c2a50c0.png',
  },

  {
    url: `${ROUTES.BRANDS}/rotella-s-italian-bakery/`,

    name: "Rotella's Italian Bakery",

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/e880948f6cec5370a3a6330fc879d2db.png',
  },

  {
    url: `${ROUTES.BRANDS}/sambazon/`,

    name: 'Sambazon',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/4524e6f2002f1dea1e3db0b9dbd1e019.png',
  },

  {
    url: `${ROUTES.BRANDS}/sauce-craft/`,

    name: 'Sauce Craft',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand_saucecraft-min.png',
  },

  {
    url: `${ROUTES.BRANDS}/smart-balance-buttery-spread/`,

    name: 'Smart Balance Buttery Spread',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand_smartbalance.png',
  },

  {
    url: `${ROUTES.BRANDS}/smithfield/`,

    name: 'Smithfield',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/8660830e38016f672e2c6050453c60a6.png',
  },

  {
    url: `${ROUTES.BRANDS}/state-fair/`,

    name: 'State Fair',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/91dcb0afb6d65122706c49f4659a4a0d.png',
  },

  {
    url: `${ROUTES.BRANDS}/steak-eze/`,

    name: 'Steak-EZE',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/55bd5463b19be2b932bd7778c9694f64.png',
  },

  {
    url: `${ROUTES.BRANDS}/sunglow/`,

    name: 'SunGlow',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand_sunglow_226x113.png',
  },

  {
    url: `${ROUTES.BRANDS}/super-bakery/`,

    name: 'Super Bakery',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/61fd8d2bfdffd07aee7dce01b48a9877.png',
  },

  {
    url: `${ROUTES.BRANDS}/sweet-street/`,

    name: 'Sweet Street',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand_sweetstreet.png',
  },

  {
    url: `${ROUTES.BRANDS}/the-max/`,

    name: 'The Max',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/2c342c665db36b86cfe2189dae51b513.png',
  },

  {
    url: `${ROUTES.BRANDS}/tony's/`,

    name: "Tony's",

    logo: 'https://drryor7280ntb.cloudfront.net/media/Tony_s_logo-_002_.jpg',
  },

  {
    url: `${ROUTES.BRANDS}/tornados/`,

    name: 'Tornados',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/51eb6a3bc4051d99316798322103ca02.png',
  },

  {
    url: `${ROUTES.BRANDS}/tyson/`,

    name: 'Tyson',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/c1239a6417d7da966706308083a37300.png',
  },

  {
    url: `${ROUTES.BRANDS}/tyson-red-label/`,

    name: 'Tyson Red Label',

    logo: 'https://drryor7280ntb.cloudfront.net/media/brand/79494004f4017dcc9455b28ac76b2d54.png',
  },

  {
    url: `${ROUTES.BRANDS}`,

    name: 'Show All',

    logo: '/assets/images/show-all.png',
  },
]
