import Link from '@components/ui/link'
import { ROUTES } from '@utils/routes'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosPlay } from 'react-icons/io'
import { Option } from './account-nav'

interface AccountBreadCrumbsProps {
  options: Option[]
}

const items = [
  { label: 'text-home', link: ROUTES.HOME },
  { label: 'text-page-explore', link: ROUTES.ACCOUNT },
]

const AccountBreadCrumbs: React.FC<AccountBreadCrumbsProps> = ({ options }) => {
  const { t: translate } = useTranslation(['common', 'dashboard'])
  const router = useRouter()
  const { pathname } = router

  const currentSelectedItem = pathname
    ? options.find((o) => o.slug === pathname)!
    : options[0]

  const [selectedItem, setSelectedItem] = useState<Option>(currentSelectedItem)
  useEffect(() => {
    setSelectedItem(currentSelectedItem)
  }, [currentSelectedItem])

  return (
    <nav
      className="text-brand-gray text-sm sm:text-base font-sofia font-medium my-3 pl-2"
      aria-label={translate('text-breadcrumb-label', { ns: 'dashboard' })}
    >
      <ol className="flex space-x-2">
        {items.map((item, index) => (
          <li key={index} className="flex items-center">
            <Link
              data-testid={`account-dashboard-breadcrumb(${index})`}
              href={item.link}
              className="underline"
            >
              {translate(item.label)}
            </Link>
            <IoIosPlay className="text-brand-gray ml-2 text-xs" />
          </li>
        ))}
        <span className="underline">{translate(selectedItem?.name)}</span>
      </ol>
    </nav>
  )
}

export default AccountBreadCrumbs
