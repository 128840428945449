import Link from 'next/link'
import { useRouter } from 'next/router'
import { useLogoutMutation } from '@framework/auth/use-logout'
import { useTranslation } from 'next-i18next'
import { IUser } from '@contexts/user/user.utils'
import { UserContext } from '@contexts/user/user.context'
import { FC, useContext } from 'react'
import { accountMenu } from './account-layout'

const AccountDropDownNav: FC<any> = ({ setOpenDropDown }) => {
  const { cartId, isHomeBuyer } = useContext(UserContext) as IUser
  const { t } = useTranslation('common')
  const { mutate: logout } = useLogoutMutation()
  const { push } = useRouter()

  const showAddToList = process.env.NEXT_PUBLIC_SHOW_ADD_TO_LIST === 'true'
  const showTrevipay = process.env.NEXT_PUBLIC_SHOW_TREVIPAY === 'true'

  const logoutHandler = () => {
    logout()
    setOpenDropDown(false)
  }

  const handleredirection = (e, url) => {
    e.preventDefault()
    e.stopPropagation()
    push(url)
    setOpenDropDown(false)
  }

  return (
    <nav className="py-3">
      {accountMenu.map((item) => {
        if (
          (!cartId?.canUseReferralProgramAndSpend &&
            item.name === 'text-referral') ||
          (!showAddToList && item.name === 'text-my-save-list') ||
          (!showTrevipay && item.name === 'text-trevipay') ||
          (isHomeBuyer && item.name === 'text-rebates')
        )
          return

        return (
          <Link
            key={item.slug}
            href={item.slug}
            onClick={(e) => {
              handleredirection(e, item.slug)
            }}
            className={`flex items-center font-medium underline cursor-pointer text-sm text-[]  text-brand my-3`}
          >
            {t(item.name)}
          </Link>
        )
      })}
      <button
        className="flex items-center text-sm font-medium  text-red-700  cursor-pointer focus:outline-none"
        onClick={logoutHandler}
      >
        <span className="ltr:pl-1 lg:rtl:pr-1.5 underline cursor-pointer !pointer-events-auto">
          {t('text-logout')}
        </span>
      </button>
    </nav>
  )
}

export default AccountDropDownNav
