import { useTranslation } from 'next-i18next'
import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react'
import { IoClose } from 'react-icons/io5'
import AccountDropDownNav from './account-dropdown-nav'

interface AccountDropDownProps {
  openDropDown: boolean
  setOpenDropDown: Dispatch<SetStateAction<boolean>>
  closeAccountDropDown
}
const AccountDropDown: FC<AccountDropDownProps> = ({
  openDropDown,
  setOpenDropDown,
  closeAccountDropDown,
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpenDropDown(false)
      }
    }

    if (openDropDown) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [openDropDown, closeAccountDropDown])
  const { t: translate } = useTranslation()

  return openDropDown ? (
    <div
      ref={dropdownRef}
      className="bg-brand-light absolute p-4 min-w-[250px] right-0 drop-shadow-lg rounded-sm"
    >
      <div className=" flex justify-between items-center">
        <h1 className="text-brand-dark  font-semibold font-sofia">
          {translate('text-page-explore')}
        </h1>
        <IoClose
          onClick={closeAccountDropDown}
          fontSize={22}
          className="relative cursor-pointer text-gray-500 ml-auto hover:opacity-60"
        />
      </div>
      <AccountDropDownNav setOpenDropDown={setOpenDropDown} />
    </div>
  ) : null
}

export default AccountDropDown
