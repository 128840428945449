import { COUNTRY_CANADA, COUNTRY_US } from '@constants/index'
import { useTranslation } from 'react-i18next'

type ProductItem = {
  brand?: string
  name?: string
  attributes?: {
    case_description?: string
  }
  case_quantity?: number
}

const ProductTitle = ({ item }: { item: ProductItem }) => {
  const { t: translate } = useTranslation('common')
  if (!item) return null

  const COUNTRY_ID = process.env.NEXT_PUBLIC_WEBSITE_COUNTRY || COUNTRY_US

  const brandPart =
    item.brand && item.name && !item.name.includes(item.brand)
      ? `${item.brand} `
      : ''

  const namePart = item.name ? `${item.name} ` : ''

  const caseDescriptionPart = item.attributes?.case_description
    ? `(${item.attributes.case_description}) `
    : ''

  const caseQuantityPart =
    COUNTRY_ID === COUNTRY_CANADA && item.case_quantity
      ? `${item.case_quantity} ${translate('text-per-case')}`
      : ''

  return (
    <>
      {brandPart}
      {namePart}
      {caseDescriptionPart}
      {caseQuantityPart}
    </>
  )
}

export default ProductTitle
