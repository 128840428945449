import Button from '@components/ui/button'
import Link from '@components/ui/link'
import TextContentLoader from '@components/ui/loaders/text-content-loader'
import { BRANDS } from '@constants/brands'
import { ROUTES } from '@utils/routes'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

export default function ShopByBrandsGrid({
  setIsOpenShopByBrand,
  featuredManufacturers = null,
}) {
  const { t: translate } = useTranslation('common')
  const router = useRouter()

  const featuredManufacturersList = featuredManufacturers

  return (
    <div className="p-5 overflow-auto">
      <div className="flex justify-between mb-5">
        <h3 className="text-xl font-medium text-brand">
          {translate('text-featured-brands', { ns: 'common' })}
        </h3>

        <div className="text-xl font-medium text-brand">
          <Link
            href={ROUTES.BRANDS}
            onClick={() => {
              setIsOpenShopByBrand(false)
            }}
            className="w-full px-4 py-1 !h-4 text-[14px] rounded-md !font-medium font-poppins bg-brand text-white shadow-sm transition-shadow duration-200 hover:opacity-90 mr-3"
          >
            {translate('text-view-all-brands', { ns: 'common' })}
          </Link>

          <Link
            href={ROUTES.MANUFACTURERS}
            onClick={() => {
              setIsOpenShopByBrand(false)
            }}
            className="w-full px-4 py-1 !h-4 text-[14px] rounded-md !font-medium font-poppins bg-brand text-white shadow-sm transition-shadow duration-200 hover:opacity-90"
          >
            {translate('text-view-all-manufacturers', { ns: 'common' })}
          </Link>
        </div>
      </div>
      <div className="w-full px-0 mx-auto">
        <div className="flex flex-wrap mx-0">
          <div className="flex-[0_0_30%] max-width-[30%]">
            {featuredManufacturersList && (
              <ul className="w-full flex flex-col items-center justify-center text-center">
                {featuredManufacturersList?.map((manufacturer, index) => (
                  <Link
                    href={manufacturer.url}
                    onClick={() => {
                      setIsOpenShopByBrand(false)
                    }}
                    key={`brand-${index}`}
                    className="w-full flex flex-col items-center justify-center text-center"
                  >
                    <li
                      key={index}
                      className="w-full h-[106px] bg-white mb-4 shadow-dropDown flex pl-[70px] shopByBrand items-center overflow-hidden rounded-md"
                    >
                      <Image
                        src={manufacturer.logo}
                        alt={manufacturer.name}
                        width={118}
                        height={59}
                      />
                      <span className="text-grey-900 text-[17px] font-medium pl-[20px]">
                        {manufacturer.name}
                      </span>
                    </li>
                  </Link>
                ))}
              </ul>
            )}

            {!featuredManufacturersList && <TextContentLoader />}
          </div>
          <div className="flex-[0_0_70%] max-width-[70%]">
            <ul className="flex flex-wrap w-full gap-2 pl-7">
              {BRANDS?.map((brand, index) => (
                <>
                  {brand?.name !== 'Show All' ? (
                    <Link
                      href={brand.url}
                      key={index}
                      onClick={() => {
                        setIsOpenShopByBrand(false)
                      }}
                    >
                      <li className="w-[100px] h-[106px] bg-white hover:bg-gray-50 rounded-md mb-4 shadow-[0_6px_10px_5px_rgba(0,0,0,0.1)] flex justify-around items-center">
                        <Image
                          src={brand.logo}
                          alt={brand.name}
                          width={80}
                          height={44}
                        />
                      </li>
                    </Link>
                  ) : (
                    <Link
                      href={brand.url}
                      key={index}
                      onClick={() => {
                        setIsOpenShopByBrand(false)
                      }}
                    >
                      <li className="w-[100px] h-[106px] bg-white hover:bg-gray-50 rounded-md mb-4 shadow-[0_6px_10px_5px_rgba(0,0,0,0.1)] text-brand font-sofia-pro font-semibold flex justify-around items-center">
                        Show All
                      </li>
                    </Link>
                  )}
                </>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
