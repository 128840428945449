import { getSessionStore } from '@utils/helper'
import { ROUTES } from '@utils/routes'
import { getSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { ReactNode, useEffect, useState } from 'react'
import { useModalAction } from './common/modal/modal.context'

interface ProtectedRouteProps {
  children: ReactNode
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(true)
  const { openModal } = useModalAction()

  function handleLogin() {
    router.push(ROUTES?.LOGIN)
  }

  useEffect(() => {
    async function checkUser() {
      const session = getSessionStore() || (await getSession())

      if (!session || session?.isGuest === true) {
        if (typeof window !== 'undefined') {
          await router.push(ROUTES.HOME)
          handleLogin()
          return null
        }
      }

      setIsLoading(false)
    }

    checkUser()
  }, [router])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return <>{children}</>
}

export default ProtectedRoute
