import { productPlaceholder } from '@assets/placeholders'
import { useModalAction } from '@components/common/modal/modal.context'
import Image from '@components/ui/image'
import { useCart } from '@contexts/cart/cart.context'
import usePrice from '@framework/product/use-price'
import { Product } from '@framework/types'
import { convertProduct } from '@framework/utils/convert-product'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { FC, useContext, useState } from 'react'

import Counter from '@components/ui/counter'
import { CommonProductTitle } from '@utils/common-product-title'
import { generateCartItem } from '@utils/generate-cart-item'
import {
  createPricePayload,
  getCurrentPrice,
  getDiscountedPrice,
  getOldPrice,
  getShipInDays,
  isSpecialOrder,
} from '@utils/helper'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { IoStarSharp } from 'react-icons/io5'
import StarRatingComponent from 'react-star-rating-component'
import styles from './product-card-search-overlay.module.scss'
import { UserContext } from '@contexts/user/user.context'
import { IUser } from '@contexts/user/user.utils'
import { useSession } from 'next-auth/react'

const AddToCart = dynamic(() => import('@components/product/add-to-cart'), {
  ssr: false,
})
interface ProductProps {
  product: Product
  className?: string
  page?: string
  uniqueKey?: string
}

const ProductCardMini = ({
  product,
  gallery,
  name,
  brand,
  productUrl,
  page = 'home',
  uniqueKey = '',
}) => {
  const { data: session } = useSession()
  const router = useRouter()
  const { user, isHomeBuyer } = useContext(UserContext) as IUser
  const [selectedQuantity, setSelectedQuantity] = useState(1)
  const { closeModal } = useModalAction()
  const [addToCartLoader, setAddToCartLoader] = useState<boolean>(false)
  const { t: translate } = useTranslation('common')
  const { addItemToCart } = useCart()
  const {
    case_quantity,
    stock_type,
    shipping_type,
    is_active,
    is_in_stock,
    flags,
    ships_in_days,
    price,
    special_price,
    special_from_date,
    special_to_date,
    mag_attributes,
    attributes,
    catalog_rule_price,
  } = convertProduct(product) || {}

  const isInStock = is_in_stock || false
  const isNewArrival = flags?.includes('new_arrivals') || false
  const isRebateEligible =
    (!isHomeBuyer && flags?.includes('rebate_eligible')) || false
  const isClearance = flags?.includes('liquidation') || false
  const isFreeShipping = shipping_type === 'Ships Free'
  const isSpecialOrderFlag = isSpecialOrder(product)

  const isBuyMoreSaveMore =
    mag_attributes?.find((item) => item.attribute_code === 'tier_prices')?.value
      ?.length > 0

  const shipsInDays = getShipInDays(ships_in_days, translate)

  const priceDetails = {
    special_price,
    special_from_date,
    special_to_date,
    price,
  }

  const currentPrice = getCurrentPrice(createPricePayload(product))
  const oldPrice = getOldPrice(createPricePayload(product))

  const tierPrices = product?.mag_attributes?.find(
    (attr: { attribute_code: string }) => attr.attribute_code === 'tier_prices',
  )?.value

  const finalCurrentPrice = getDiscountedPrice(
    user?.group_id,
    catalog_rule_price,
    currentPrice,
    session,
    tierPrices,
  )

  const { price: formattedCurrentPrice } = usePrice({
    amount: finalCurrentPrice,
    baseAmount: price,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })

  const { price: formattedOldPrice } = usePrice({
    amount: oldPrice ?? 0, // If old price is null, pass 0 to the hook
    baseAmount: price,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })

  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    let numberFlag = isNaN(parseInt(event.target.value))
    let newValue = numberFlag ? 1 : parseInt(event.target.value)
    if (newValue < 1) return false
    setSelectedQuantity(newValue)
  }

  function addToCart() {
    setAddToCartLoader(true)
    setTimeout(() => {
      setAddToCartLoader(false)
    }, 1500)
    const item = generateCartItem(product!)
    const updatedItem = {
      ...item,
    }
    addItemToCart(updatedItem, selectedQuantity)
  }

  function navigateToProductPage(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault()
    event.stopPropagation()
    closeModal()
    if (router.pathname === '/[product_pdp]') {
      window.location.href = productUrl
    } else router.push(productUrl)
  }

  return (
    <div className={styles['product-card']}>
      <div
        className="flex flex-col items-start tags absolute gap-1 top-0 left-0"
        data-testid={`${page}-tag-${uniqueKey}-parentElement`}
      >
        {isNewArrival && (
          <span
            className={`text-[10px] md:text-[12px] font-medium z-10 w-auto text-center px-2 py-0.5 text-brand-blue border bg-fill-whiteOpacity rounded-[15px] border-solid border-brand-blue left-2.5 top-2.5`}
            data-testid={`${page}-tag-${uniqueKey}-new-arrivals`}
          >
            {/* New Arrivals */}
            {translate('text-new-arrivals')}
          </span>
        )}

        {isRebateEligible && (
          <span
            className={`text-[10px] md:text-[12px] font-medium z-10 w-auto text-center px-2 py-0.5 text-orange border bg-fill-whiteOpacity rounded-[15px] border-solid border-orange left-2.5 top-10`}
            data-testid={`${page}-tag-${uniqueKey}-rebate-eligible`}
          >
            {translate('text-rebate-eligible')}
          </span>
        )}

        {isFreeShipping && (
          <span
            className={`text-[10px] md:text-[12px] font-medium z-10 w-auto text-center px-2 py-0.5 text-brand-green border bg-fill-whiteOpacity rounded-[15px] border-solid border-green left-2.5 top-[70px]`}
            data-testid={`${page}-tag-${uniqueKey}-free-shipping`}
          >
            {/* Free Shipping */}
            {translate('text-free-shipping')}
          </span>
        )}

        {isClearance && (
          <span
            className={`text-[10px] md:text-[12px] font-medium z-10 w-auto text-center px-2 py-0.5 text-brand-pink border bg-fill-whiteOpacity rounded-[15px] border-solid border-brand-pink left-2.5 top-[70px]`}
            data-testid={`${page}-tag-${uniqueKey}-liquidation`}
          >
            {/* Clearance */}
            {translate('text-liquidation')}
          </span>
        )}

        {!isInStock && (
          <span
            className={`text-[10px] md:text-[12px] font-medium z-10 w-auto text-center px-2 py-0.5 text-brand-red border bg-fill-whiteOpacity rounded-[15px] border-solid border-brand-red left-2.5 top-[70px]`}
            data-testid={`${page}-tag-${uniqueKey}-out-of-stock`}
          >
            {/* Out of Stock */}
            {translate('text-out-of-stock')}
          </span>
        )}
      </div>
      <img
        src={
          ((product && product['image.thumbnail']) ||
            (gallery && gallery?.[0]?.thumbnail)) ??
          productPlaceholder
        }
        alt={name || 'Product Image'}
        className={styles['product-image']}
      />
      <div className={styles['product-details']}>
        <p className={styles['product-name']}>
          <strong>
            {brand && name && name.indexOf(String(brand)) === -1
              ? String(brand).toLocaleLowerCase()
              : ''}{' '}
          </strong>
          {String(name).toLocaleLowerCase()}
        </p>
        <p className={styles['price']}>
          {formattedCurrentPrice.replace('CA', '')}{' '}
          {oldPrice && (
            <span className={styles['old-price']}>
              {formattedOldPrice.replace('CA', '')}
            </span>
          )}
        </p>
      </div>
    </div>
  )
}

const ProductCardSearchOverlay: FC<ProductProps> = ({
  product,
  className,
  page = 'home',
  uniqueKey = '',
}) => {
  const router = useRouter()
  const { locale } = router

  const { name, image, product_type, brand, case_quantity, gallery, slug } =
    convertProduct(product, locale) ?? {}
  const { openModal } = useModalAction()

  const { price, basePrice, discount } = usePrice({
    amount: product?.sale_price ? product?.sale_price : product?.price,
    baseAmount: product?.price,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })
  const { price: minPrice } = usePrice({
    amount: product?.min_price ?? 0,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })
  const { price: maxPrice } = usePrice({
    amount: product?.max_price ?? 0,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })

  const productUrl = `/${slug}`

  function handlePopupView() {
    if (router.pathname === '/[product_pdp]') {
      window.location.href = productUrl
    } else openModal('PRODUCT_VIEW', product)
  }

  return (
    <article
      className={`${cn(
        'block group rounded-md cursor-pointer transition-all duration-300 h-full overflow-visible',
        className,
      )}`}
      onClick={handlePopupView}
      title={name}
    >
      <ProductCardMini
        product={product}
        gallery={gallery}
        name={name}
        brand={brand}
        productUrl={productUrl}
        product_type={product_type}
        basePrice={basePrice}
        minPrice={minPrice}
        maxPrice={maxPrice}
        page={page}
        uniqueKey={uniqueKey}
      />
    </article>
  )
}
export default ProductCardSearchOverlay
