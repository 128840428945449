import MapIcon from '@components/icons/account-address'
import HelpIcon from '@components/icons/account-help'
import AccountInformationIcon from '@components/icons/account-information'
import NoticeIcon from '@components/icons/account-notice'
import NotificationIcon from '@components/icons/account-notification'
import OrdersIcon from '@components/icons/account-order'
import SettingsIcon from '@components/icons/account-settings'
import WishlistIcon from '@components/icons/account-wishlist'
import AccountNav from '@components/my-account/account-nav'
import Container from '@components/ui/container'
import { ROUTES } from '@utils/routes'
import { IoSettingsOutline } from 'react-icons/io5'
import AccountNavMobile from './account-nav-mobile'
import ProtectedRoute from '@components/ProtectedRoute'
import { FiAward, FiBarChart2 } from 'react-icons/fi'
import { PropsWithChildren } from 'react'
import { TfiBookmark } from 'react-icons/tfi'
import AccountBreadCrumbs from './account-breadcrumbs'
import { RiBox3Line } from 'react-icons/ri'
import DollarCase from '@components/icons/dollar-case'

export const accountMenu = [
  {
    slug: ROUTES.ACCOUNT_SETTING,
    name: 'account-settings',
    icon: <SettingsIcon className="w-5 md:w-[22px] h-5 md:h-[22px]" />,
  },
  {
    slug: ROUTES.ORDERS,
    name: 'text-orders',
    icon: <OrdersIcon className="w-5 md:w-[22px] h-5 md:h-[22px]" />,
  },
  {
    slug: ROUTES.ACCOUNT_INFORMATION,
    name: 'text-account-information',
    icon: <SettingsIcon className="w-5 md:w-[22px] h-5 md:h-[22px]" />,
  },
  {
    slug: ROUTES.ADDRESS,
    name: 'text-address',
    icon: <MapIcon className="w-5 md:w-[22px] h-5 md:h-[22px]" />,
  },
  {
    slug: ROUTES.NEWS_LETTER,
    name: 'text-newsletter-subscriptions',
    icon: (
      <FiBarChart2 className="text-brand-gray w-5 md:w-[22px] h-5 md:h-[22px]" />
    ),
  },
  {
    slug: ROUTES.REFERRAL_REWARDS,
    name: 'text-referral',
    icon: (
      <FiAward className="w-5 md:w-[22px] h-5 md:h-[22px] text-brand-lightgray" />
    ),
  },
  {
    slug: ROUTES.PRODUCT_REVIEWS,
    name: 'text-product-reviews',
    icon: (
      <RiBox3Line className="w-5 md:w-[22px] h-5 md:h-[22px] text-brand-lightgray" />
    ),
  },
  {
    slug: ROUTES.TREVIPAY,
    name: 'text-trevipay',
    icon: (
      <TfiBookmark className="w-5 md:w-[22px] h-5 md:h-[22px] text-brand-lightgray" />
    ),
  },
  {
    slug: ROUTES.REBATES,
    name: 'text-rebates',
    icon: <DollarCase className="w-5 md:w-[22px] h-5 md:h-[22px]" />,
  },
  {
    slug: ROUTES.SAVED_LISTS,
    name: 'text-my-save-list',
    icon: (
      <TfiBookmark className="w-5 md:w-[22px] h-5 md:h-[22px] text-brand-lightgray" />
    ),
  },
  {
    slug: ROUTES.STORED_PAYMENT_METHODS,
    name: 'text-stored-payment-method',
    icon: (
      <FiAward className="w-5 md:w-[22px] h-5 md:h-[22px] text-brand-lightgray" />
    ),
  },
]

export default function AccountLayout({ children }: PropsWithChildren<{}>) {
  return (
    <ProtectedRoute>
      <div className="border-t border-b border-border-base">
        <Container>
          <div className="pt-10 2xl:pt-12 pb-12 lg:pb-14 xl:pb-16 2xl:pb-20 xl:max-w-screen-xl 2xl:max-w-[1300px] mx-auto">
            <AccountBreadCrumbs options={accountMenu} />
            <div className="flex flex-col w-full lg:flex-row">
              <div className="lg:hidden">
                <AccountNavMobile options={accountMenu} />
              </div>
              <div className="hidden lg:block shrink-0 w-72 xl:w-[385px] ltr:mr-7 rtl:ml-7 xl:ltr:mr-8 xl:rtl:ml-8">
                <AccountNav options={accountMenu} />
              </div>

              <div className="w-full p-4 mt-4 border rounded-md lg:mt-0 border-border-base sm:p-5 lg:py-8 2xl:py-10 lg:px-7 2xl:px-12 overflow-x-auto">
                {children}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </ProtectedRoute>
  )
}
