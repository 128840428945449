import { FC, SVGAttributes } from 'react'

const DollarCase: FC<SVGAttributes<{}>> = ({ ...rest }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <mask
        id="mask0_1_8"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path d="M24 0H0V24H24V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1_8)">
        <path
          d="M9.70778 1.875C8.74215 2.12813 7.9359 2.94376 7.65933 3.93751C7.61716 4.09689 7.57964 4.51406 7.5609 5.025L7.53277 5.85937H4.78122C3.10308 5.85937 1.93122 5.87812 1.77652 5.90626C0.923401 6.07032 0.206213 6.7875 0.0468384 7.63595C-0.0187866 7.97813 -0.0187866 20.1 0.0468384 20.4421C0.154651 21.0281 0.590588 21.647 1.10621 21.9375C1.62653 22.2376 0.895276 22.2187 12 22.2187C23.1047 22.2187 22.3734 22.2376 22.8937 21.9375C23.4094 21.647 23.8453 21.0281 23.953 20.4421C24.0188 20.1 24.0188 7.97813 23.953 7.63595C23.7937 6.7875 23.0765 6.07032 22.2234 5.90626C22.0687 5.87812 20.8968 5.85937 19.2188 5.85937H16.4672L16.439 5.025C16.4062 4.10156 16.3593 3.8625 16.0968 3.33281C15.8812 2.89689 15.3843 2.38125 14.9484 2.14219C14.339 1.8047 14.1562 1.78125 11.9812 1.78594C10.2328 1.78594 10.0218 1.79532 9.70778 1.875ZM14.1609 3.36563C14.4562 3.51094 14.7093 3.76875 14.85 4.07812C14.9625 4.31251 14.9765 4.40157 14.9906 5.09532L15.0093 5.85937H12H8.98589L9.00934 5.09532C9.03746 4.17656 9.10308 3.975 9.49683 3.61407C9.90933 3.23437 9.88121 3.23907 12.0468 3.24844C13.9171 3.25782 13.95 3.25782 14.1609 3.36563ZM12.4687 9.375C14.4375 9.58125 16.0172 10.9359 16.5562 12.8812C16.6453 13.1859 16.6593 13.3641 16.6593 14.0625C16.6593 14.8172 16.6453 14.9156 16.5328 15.3047C16.1578 16.5609 15.3093 17.6063 14.2078 18.1782C13.6359 18.4734 13.2703 18.5953 12.7078 18.6797C12.1406 18.7688 11.8593 18.7688 11.2922 18.6797C10.7296 18.5953 10.364 18.4734 9.79214 18.1782C8.5359 17.5266 7.64996 16.3172 7.37809 14.8781C7.27496 14.3297 7.30778 13.3969 7.44371 12.8812C7.94997 10.9875 9.56714 9.58125 11.4703 9.37968C11.9859 9.32344 11.9765 9.32344 12.4687 9.375Z"
          fill="#8C969F"
        />
        <path
          d="M11.8125 10.589V10.8609L11.5407 10.8844C11.161 10.9219 10.8328 11.0766 10.5844 11.3344C10.2 11.7375 10.0453 12.1547 10.0922 12.6656C10.1578 13.3922 10.6547 13.8562 11.5875 14.0719L11.8125 14.1234V15.2437V16.364L11.6391 16.3312C11.0532 16.2234 10.8375 15.9656 10.7016 15.2344L10.6641 15.0234L10.3172 15.0094L9.97034 14.9953L9.99846 15.3703C10.0266 15.7969 10.1578 16.1437 10.4016 16.4203C10.6032 16.65 11.0719 16.875 11.4797 16.9359L11.8125 16.9875V17.3765V17.7656H12H12.1875V17.3765V16.9875L12.525 16.9312C12.9516 16.8562 13.4016 16.6406 13.5938 16.4203C13.6781 16.3266 13.8047 16.1297 13.8703 15.9844C13.9782 15.7547 13.9922 15.6703 13.9922 15.1875C13.9922 14.7 13.9828 14.625 13.8703 14.4141C13.6969 14.0719 13.4063 13.875 12.7547 13.6547L12.211 13.4672L12.1969 12.4687L12.1875 11.4703L12.3797 11.4984C12.6703 11.5359 12.9235 11.7328 13.0594 12.0141C13.1203 12.15 13.1719 12.2953 13.1719 12.3375C13.1719 12.4125 13.2188 12.4219 13.5422 12.4219H13.9078L13.8703 12.1875C13.7578 11.4797 13.2282 10.9969 12.4407 10.8844L12.211 10.8516L12.1969 10.5797L12.1828 10.3125H12H11.8125V10.589ZM11.8125 12.4266V13.3687L11.6625 13.3406C11.175 13.2422 10.8703 12.9469 10.8375 12.5344C10.7907 11.9672 11.1657 11.5031 11.686 11.4891L11.8125 11.4844V12.4266ZM12.736 14.4422C12.8953 14.5172 13.0688 14.6391 13.1203 14.7141C13.275 14.9297 13.3313 15.2344 13.2657 15.5297C13.1672 16.0031 12.8578 16.2984 12.3891 16.35L12.1875 16.3734V15.3047V14.2406L12.3188 14.2687C12.3891 14.2875 12.5766 14.3625 12.736 14.4422Z"
          fill="#8C969F"
        />
      </g>
    </svg>
  )
}

export default DollarCase
